import React from "react";
import { Link } from "react-router-dom";

const BlogContent = [
  {
    img: "blog1",
    date: "14 Jun. 2024",
    title: `Software For Small Business Management`,
    desc: `Are you a small business owner? Yes? Do you want to take your business productivity, efficiency, 
    and performance to the next level? Yes? Then,
    you should leverage best-in-class software to manage your business and get the desired success.
    Leveraging cutting-edge technology enables budding entrepreneurs to boost their business growth. When you 
    choose to grow and expand your venture, it becomes harder day by day to manage or keep track of everything on your own.  ` ,
    routePath: "/software-for-small-business-management",
  },
  {
    img: "blog2",
    date: "14 Jun. 2024",
    title: `How To Improve Sales Productivity and Boost Team Efficiency?`,
    desc: `Do you wonder why you should emphasize your sales team’s productivity and efficiency? Are you looking to improve your business performance and profit margin? Yes? Then, let the blog post help you out!
    Why is it Crucial to Boost the Productivity & Efficiency of Your Sales Team?  The growth and success of every business largely depend on its sales team. In other words, the sales team acts as 
    the backbone of an enterprise to help keep the business going.` ,
    routePath: "/blog-how-to-improve-sales-productivity-and-boost",
  },
  
    
];

const BlogStyle3 = () => {
  return (
    <>
      {BlogContent.map((item, i) => (
        <div className="post-meta" key={i}>
          <img
            src={`images/assets/${item.img}.jpg`}
            alt="media"
            className="image-meta"
          />
          <div className="post">
            <div className="date">{item.date}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <p style={{
              fontSize: '18px',
              color: 'black',
              letterSpacing: '1.12px',
            }}>{item.desc}</p>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Continue Reading</span>
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
          {/* End post */}
        </div>
        // /.post-meta
      ))}
    </>
  );
};

export default BlogStyle3;
