import React from "react";
// Route Specific
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES

import ClientCtrl from "../views/all-home-pages/ClientCtrl";
import TermsAndCondition from "../views/inner-pages/service/TermsAndCondition";

// Contact us inner pages
import ContactClientCtrl from "../views/inner-pages/pages/contact/ContactClientCtrl";


// About us inner pages

import Communication from "../views/inner-pages/features/Communication";
import AboutDocumentation from "../views/inner-pages/pages/about/AboutDocumentation";

// Pricing inner pages
import PricingClientCtrl from "../views/inner-pages/pages/pricing/PricingClientCtrl";


// FEATURES DROPDOWN ALL ROUTES
import Login from "../views/inner-pages/features/miscellaneous/Login";
import SignUp from "../views/inner-pages/features/miscellaneous/SignUp";
import ClientManagement from "../views/inner-pages/features/ClientManagement";
import LeadsManagement from "../views/inner-pages/features/LeadsManagement";
import Overview from "../views/inner-pages/features/Overview";
import OrdersManagement from "../views/inner-pages/features/OrdersManagement";

// BLOGS DROPDOWN ALL ROUTES
import BlogOne from "../views/inner-pages/blog-pages/BlogOne";
import BlogV4 from "../views/inner-pages/blog-pages/BlogV4";
import BlogTwo from "../views/inner-pages/blog-pages/BlogTwo";

// Not Found Page
import NotFound from "../views/NotFound";


// Demo

import ClientDemo from "../components/demo/ClientDemo";
import PricingYearly from "../views/inner-pages/pages/pricing/PricingYearly";
import Test from "../components/pricing/pricing-one/Test";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        
       
        {/* products Dropdown Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
        
        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />

        <Route path="/demo" element={<ClientDemo />} />

        {/* Home */}
        <Route path="/" element={<ClientCtrl />} />

        {/* Products */}
        <Route
          path="/clientctrl-features"
          element={<Overview />}
        />
        <Route path="/features-client-management" element={<ClientManagement />} />
        <Route path="/features-leads-management" element={<LeadsManagement />} />
        <Route
          path="/features-orders-management"
          element={<OrdersManagement />}
        />
        <Route path="/features-communication" element={<Communication />} />

        {/* About Us */}
        <Route path="/about" element={<AboutDocumentation />} />

        {/* Contact */}
        <Route path="/contact" element={<ContactClientCtrl />} />
        
        {/* Pricing */}
        <Route path="/pricing" element={<PricingClientCtrl />} />
        <Route path="/pricingYearly" element={<PricingYearly/>} />
        <Route path="/test" element={<Test/>} />

        {/* Blog */}
        <Route path="/blog" element={<BlogV4 />} />
        <Route path="/software-for-small-business-management" element={<BlogOne />} />
        <Route path="/blog-how-to-improve-sales-productivity-and-boost" element={<BlogTwo />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
