import React from "react";

const FeatureContent = [
  {
    icon: "35",
    meta: "One-time Price",
    subTitle: ` Save thousands of dollars with this single software purchase, eliminating recurring expenses`,
    dataDelay: "0",
  },
  {
    icon: "36",
    meta: "Intuitive Interface",
    subTitle: ` A modern web application featuring an intuitive interface that is effortlessly adopted by all users`,
    dataDelay: "100",
  },
  {
    icon: "37",
    meta: "Unparallel Support",
    subTitle: `We are committed to providing customer support that goes above and beyond your expectations`,
    dataDelay: "300",
  },
  {
    icon: "35",
    meta: "Regular Updates",
    subTitle: ` We guarantee regular software updates that include brand new features, free of charge`,
    dataDelay: "0",
  },
  {
    icon: "36",
    meta: "Customization Service",
    subTitle: `Our team of experts guarantees to tailor the software exactly to your specifications at best rate`,
    dataDelay: "100",
  },
  {
    icon: "37",
    meta: "Money-back Guarantee",
    subTitle: `If you are not completely satisfied within 14 days of purchase, we will refund your money`,
    dataDelay: "300",
  },
];

const FancyFeatureSeven = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6  "
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-seven">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="feature-info">
              <span>{val.meta}</span>
            </div>
            <p className="font-rubik" style={{fontSize:"22px"}}>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default FancyFeatureSeven;
