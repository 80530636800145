import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../components/header/HeaderTwo";
import HeroBannerTwo from "../../components/hero-banner/HeroBannerTwo";
import FeatureFour from "../../components/features/FeatureFour";
import CallToActionThree from "../../components/call-to-action/CallToActionThree";
import FooterTwo from "../../components/footer/FooterTwo";
import CopyRightTwo from "../../components/footer/CopyRightTwo";
import FancyFeatureSeven from "../../components/features/FancyFeatureSeven";
import ClientDemo from "../../components/demo/ClientDemo";
import { Link } from "react-router-dom";
import FaqClassicThree from "../../components/faq/FaqClassicThree";

const ClientCtrl = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    // Check if the cookie exists
    if (!getCookie("allowCookies")) {
      // If cookie doesn't exist, show the toast
      setShowToast(true);
    }
  }, []);

  const setCookie = (name, value, expirationDays = null) => {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  const acceptCookies = () => {
    // Set the 'allowCookies' cookie with expiration of 7 days
    setCookie("allowCookies", "1", 7);
    // Hide the toast after accepting cookies
    setShowToast(false);
  };

  return (
    <div className="main-page-wrapper">
      {showToast && (
        <div className="fixed-bottom">
          <div
            className="bg-dark text-white w-100 mw-100"
            role="alert"
            data-autohide="false"
            style={{ zIndex: "999", opacity: "90%" }}
          >
            <div className="toast-body p-2 d-flex align-items-center flex-row">
              {/* <h4 style={{ color: 'whitesmoke' }}>Cookie Warning</h4> */}
              <p className="fs-6 col-10">
                This website stores data such as cookies to enable site
                functionality including analytics and personalization. More
                information{" "}
                <Link to="/terms-condition">
                  <u>can be found here</u>
                </Link>
                . By using this website, you automatically accept that we use
                cookies.
              </p>
              <div className="d-flex col-2 h-50 justify-content-center">
                <button
                  type="button"
                  className="btn  btn-light"
                  onClick={acceptCookies}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Helmet>
        <title>Best Small Business CRM Software | ClientCtrl</title>
        <meta
          name="description"
          content="Experience the power of our All-in-One Customer Management Software designed for Small Businesses. Streamline processes, increase efficiency, and build stronger customer relationships."
        />
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
		   Start Header
		   ============================================== */}
      <HeaderTwo />
      {/* End HeaderTwo Variation Three */}

      {/* =============================================
			Hero Banner Two
		  ============================================== */}
      <HeroBannerTwo />
      {/* End HeroBannerTwo */}

      {/* =============================================
				Fancy Feature Four
		  ============================================== */}
      <div className="fancy-feature-four mt-1">
        <div className="bg-wrapper">
          <img
            src="images/shape/18.svg"
            alt="shape"
            className="shapes shape-right"
          />
          <img
            src="images/shape/19.svg"
            alt="shape"
            className="shapes shape-left"
          />
          <div className="container">
            <div className="title-style-two text-center mb-100 md-mb-50">
              <div className="row">
                <div className="col-lg-10 col-lg-9 col-md-10 m-auto">
                  <h2 style={{ paddingBottom: "30px" }}>
                    All the Business-critical apps for your business in one
                    powerful package, at an unbeatable one-time price!
                  </h2>
                  <p>
                    A unified platform that simplifies marketing, sales,
                    customer services & content management. A truly simple CRM.
                  </p>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* End .title */}

            <div className="inner-content">
              <img
                src="images/shape/20.svg"
                alt="shape"
                className="shapes shape-one"
              />
              <img
                src="images/shape/21.svg"
                alt="shape"
                className="shapes shape-two"
              />
              <FeatureFour />
            </div>
            {/* End .inner-content */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-four */}

      {/* 


      {/* 
     =============================================
				Fancy Text block Eight
		============================================== */}
      <div className="fancy-text-block-eight pt-140 pb-200 md-pt-80 md-pb-100">
        <div className="container">
          <div className="title-style-two text-center mb-150 md-mb-70">
            <div className="row">
              <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                <p>Features</p>
                <h2>
                  Boost Sales with streamlined {""}
                  <span>
                    sales processes
                    <img src="images/shape/line-shape-2.svg" alt="shape" />
                  </span>
                </h2>
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* End .title */}

          <div
            className="block-style-six pb-100 md-pb-50"
            style={{ marginTop: "-100px", paddingBottom: "50px" }}
          >
            <div className="row">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <ul style={{ color: "black" }} className="pr-feature">
                  <li>
                    Assign leads to your team members and distribute
                    responsibilities
                  </li>
                  <li>
                    Keep follow-up information current, including next follow-up
                    date
                  </li>
                  <li>Receive reminders for upcoming follow-ups</li>
                  <li>
                    Use Customizable lead status values for efficient lead
                    qualification
                  </li>
                  <li>
                    Engage with leads directly from the portal via calls,
                    emails, WhatsApp, and SMS
                  </li>
                  <li>
                    Set deadlines for lead stages to ensure timely closure & get
                    notifications for missed deadlines
                  </li>
                  <li>
                    Real-time in-app notifications to your staff for important
                    events
                  </li>
                </ul>

                {/* /.text-details */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder illustration-one">
                  <img
                    src="images/assets/home_leads.png"
                    alt=""
                    className="ms-auto"
                    style={{ width: "80%", paddingBottom: "100px" }}
                  />
                  <div className="shapes shape-one"></div>
                  <div className="shapes shape-two"></div>
                  <div className="shapes shape-three"></div>
                  <div className="shapes shape-four"></div>
                  <div className="shapes shape-five"></div>
                  <img
                    src="images/shape/35.svg"
                    alt="shape"
                    className="shapes shape-six"
                  />
                  <img
                    src="images/shape/36.svg"
                    alt="shape"
                    className="shapes shape-seven"
                  />
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .title */}
          </div>
          {/* /.block-style-six */}

          <div className="block-style-six pt-0 md-pt-40">
            <div className="row">
              <div
                className="col-lg-5 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-details pt-0 md-pb-50">
                  <h3 className="title font-gilroy-bold">
                    Master your Customer Relationships
                  </h3>
                  {/* <p className="text-meta"> */}
                  <ul style={{ color: "black" }} className="pr-feature">
                    <li>
                      Access customer information including outstanding
                      balances, orders, income, deals, and support tickets
                    </li>
                    <li> Organize business client contacts efficiently</li>
                    <li>
                      Create new orders, deals, or generate and send quotes
                      seamlessly
                    </li>
                    <li>
                      Import clients from pre-existing systems effortlessly
                    </li>
                    <li>
                      Maintain continuous communication with clients through
                      various channels
                    </li>
                    <li>
                      Review complete communication and activity history of
                      clients
                    </li>
                    <li>
                      Utilize automated campaigns to send offers and cross-sell
                      products/services effectively
                    </li>
                    <li>
                      Use support ticketing system to enhance customer
                      experience and bolster satisfaction levels
                    </li>
                  </ul>
                  {/* </p> */}
                </div>
                {/* /.text-details */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-7 col-md-9 m-auto order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="illustration-holder illustration-two">
                  <img
                    src="images/assets/home_client.png"
                    alt=""
                    className="me-auto"
                    style={{ width: "90%", paddingBottom: "50px" }}
                  />
                  <div className="shapes shape-one"></div>
                  <div className="shapes shape-two"></div>
                  <div className="shapes shape-three"></div>
                  <div className="shapes shape-four"></div>
                  <img
                    src="images/shape/40.svg"
                    alt="shape"
                    className="shapes shape-five"
                  />
                  <img
                    src="images/shape/41.svg"
                    alt="shape"
                    className="shapes shape-six"
                  />
                </div>
              </div>
              {/* End .col */}
            </div>
          </div>
          <div
            className="block-style-six pb-100 md-pb-50"
            style={{ paddingBottom: "10px" }}
          >
            <div className="row">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <h3 className="title font-gilroy-bold">
                  Shopping Cart & Order Management
                </h3>
                <ul style={{ color: "black" }} className="pr-feature">
                  <li>
                    List services, digital products (downloadable), or physical
                    products easily.
                  </li>
                  <li>
                    Configure subscriptions including setup costs and recurring
                    fees.
                  </li>
                  <li>Customize tax rules according to your requirements.</li>
                  <li>Set shipping rates based on geography and regions.</li>
                  <li>
                    Enable clients to order through online payment gateways or
                    offline/manual tracking.
                  </li>
                  <li>Subscription payment reminders and notifications.</li>
                  <li>
                    Self-service portal for customers to manage orders,
                    invoices, payments, shipping addresses, and contact the
                    helpdesk.
                  </li>
                </ul>

                {/* /.text-details */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-7 col-md-9 m-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="illustration-holder illustration-one">
                  <div className="shapes shape-one"></div>
                  <img
                    src="images/assets/home_order.png"
                    alt=""
                    className="ms-auto"
                    style={{ width: "90%", paddingBottom: "50px" }}
                  />
                  <div className="shapes shape-two"></div>
                  <div className="shapes shape-three"></div>
                  <div className="shapes shape-four"></div>
                  <div className="shapes shape-five"></div>
                  <img
                    src="images/shape/35.svg"
                    alt="shape"
                    className="shapes shape-six"
                  />
                  <img
                    src="images/shape/36.svg"
                    alt="shape"
                    className="shapes shape-seven"
                  />
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .title */}
          </div>
        </div>
      </div>
      <div
        className="container"
        
      >
        <div className="title-style-two text-center mb-40 md-mb-40">
          <div className="row">
            <div className="col-xl-8 col-lg-9 m-auto">
              <p>More reasons to use ClientCtrl Software</p>
              <h2>Best CRM for Small Business</h2>
            </div>
          </div>
        </div>
        {/* End .title-style-two */}
        <div className="row justify-content-center">
          <FancyFeatureSeven />
        </div>
        {/* End .row */}
      </div>

      <div className="faq-section">
        <div className="container">
          <div className="title-style-two text-center mb-30 md-mb-30">
            <ClientDemo
              style={{ width: "200px", height: "50px", fontSize: "20px" }}
            />{" "}  
            <br />
            <h2 style={{ paddingTop: "30px" }}>
              Propel Your Business with the Best Management Software for Small
              Business
            </h2>
            <p style={{ paddingBottom: "20px", paddingTop: "20px" }}>
              Ditch the spreadsheets and fragmented systems.Streamline customer
              management and boost sales with ClientCtrl, an all-in-one CRM for
              small businesses like yours.
            </p>
          </div>
          {/* /.title-style-one */}

          <div className="row" style={{ marginTop: "-20px" }}>
            <div className="col-xl-9 col-lg-10 m-auto">
              {/* <FaqThree /> */}
              <FaqClassicThree />
            </div>
          </div>
          {/* /.row */}
        </div>
      </div>

      {/* /.fancy-text-block-eight */}

      {/*=====================================================
				Useable Tools
			===================================================== */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-80 md-mt-40">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ClientCtrl;
