import React, { useContext, useEffect } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
import PricingYearly from "./PricingYearly";
import "./Test.css";
import { Storecontext } from "../../../Context/Storecontext";


const PricingY = () => {
  const{setshowY,showY}=useContext(Storecontext);
  useEffect(() => {
    const data = window.localStorage.getItem("showingprice");
    if(data !== null){setshowY(JSON.parse(data))}
  }, []);

  useEffect(() => {
    window.localStorage.setItem("showingprice", JSON.stringify(showY));
  }, [showY]);

  const reload=()=>{
    setshowY(true);
    window.location.reload();
  }
  return (
    <div>
      <Tabs selectedIndex={(showY)?1:0}>
        <TabList className="nav nav-tabs justify-content-center pricing-nav-two pricing-nav-two-custom">
          <Tab onClick={() => setshowY(false)}>INR ₹</Tab>
          <Tab onClick={reload} selected>
            USD $
          </Tab>
        </TabList>

        <div className="tab-content-wrpper">
          {showY ? <PricingYearly /> : <PricingMonthly />}

          {/* End Y Year */}
        </div>
      </Tabs>
    </div>
  );
};

export default PricingY;
