import { images } from "../assets/images/media/images";

export default function Whatsapp() {
  



   
  return (
    <>
          <a href="https://wa.me/919242137092?text=Hi, I'm Interested in ClientCtrl" className="fixed-bottomRight mb-130 whatsappIcon"  >
            <img src={images.whatsapp} alt="icon" />
          </a>    
    </>
  );
}
