import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ClientDemo from "../../../components/demo/ClientDemo";


const ClientManagement = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Customer Management Software for Small Business | ClientCtrl
        </title>
        <meta name="description" content="Manage customer orders, subscriptions, dues, income, communication history, deals, support tickets, and contacts with ease using ClientCtrl. Stay organized and efficient." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent" style={{paddingTop:'70px'}}>
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
                <h1 className="heading">Truly Connect with your Clients</h1>
                <p className="sub-heading" style={{paddingBottom:'30px'}}>
                  Say goodbye to scattered notes, missed deadlines, and forgotten details. Our intuitive platform ClientCtrl 
                  empowers you to truly connect with your clients, build lasting relationships.
                </p>
                
                <ClientDemo />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}
      <div className="fancy-feature-seven" style={{paddingTop:'0'}}>
        <div className="container">
          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/client.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Create Event</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Add Client
                    </h3>
                    <p className="font-rubik">
                      Add a new customer manually from the admin panel. You can also import existing customers data from a .csv file.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/client Details.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>SELL TICKET</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Browse, Manage Clients
                    </h3>
                    <p className="font-rubik">
                      View all clients, view client status: Active/Inactive. View client’s products/services subscribed for.
                      Communicate with client by call, email, whatsapp or sms.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/customer_details.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Customer Details
                    </h3>
                    <p className="font-rubik">
                      Get a complete view of the customer in single screen. View client contact information, dues, total income, support tickets, products/services purchased, invoices, client activity, deals etc.
                      Add a new deal for a client or add a new order manually or share a new quote.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/client_contact.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Dashboard</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Client Contacts
                    </h3>
                    <p className="font-rubik">
                      Do multiple stakeholders from the client business interact with you?
                      Manage contacts associated with the client, add/edit contacts associated with the client business.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
        <div className="fancy-short-banner-two pt-130 md-pt-70">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
              >
                <h2 className="font-gilroy-bold">
                  Take the first step towards efficiency today!
                </h2>
                <ClientDemo style={{ height: '70px', width: '200px', fontSize: '20px', border: '1px solid white' }} />
                {/* <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',background:'white',color:'#7034ff',border:'1px solid black'}}/> */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
      </div>
      {/* /.fancy-feature-seven */}



      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>

  );
};

export default ClientManagement;
