import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ClientDemo from "../../../components/demo/ClientDemo";

const LeadsManagement = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
         Lead Management Software for Small Business | ClientCtrl
        </title>
        <meta name="description" content="Optimize lead organization with ClientCtrl. Access and filter leads effortlessly based on specific criteria. Stay on top of important tasks with follow-up records, activity history, reminders." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
                <h1 className="heading">Convert More Leads, Close More Deals</h1>
                <p className="sub-heading" style={{paddingBottom:'30px'}}>
                  Empowering your team with real-time insights, automated workflows, and personalized interactions that will revolutionize
                  the way you manage leads. Join us on this journey towards unparalleled success.
                </p>
                
                <ClientDemo />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven" style={{paddingTop:'0'}}>
        <div className="container">
          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/create_lead.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Create Leads</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Create Leads
                    </h3>
                    <p className="font-rubik">
                      Add a new lead quickly into the CRM with minimal lead details.
                      Select the lead source. Select the product/service the lead is interested in. Add a note about the lead.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/import.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>SELL TICKET</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Import Leads
                    </h3>
                    <p className="font-rubik">
                      Have leads data in a xl sheet? Import all the leads by importing the file.
                      Optionally assign the imported leads to a staff member.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/webtoleads.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Web form to Lead
                    </h3>
                    <p className="font-rubik">
                      Generate web forms with required fields like email, phone,
                      name etc & embed the form on your website or landing pages. Leads are auto-created in the CRM when a user submits the form.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/lead_details.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Dashboard</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Browse, Manage Leads
                    </h3>
                    <p className="font-rubik">
                      View the leads, call the lead from the same page, send email, whatsapp, sms. Change lead status, lead assignment from the same interface without leaving the page.
                      View Lead information along with last follow-up notes, next follow-up date all in the same view.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>

              </div>

              {/* /.block-style-nine */}

            </div>
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/filter.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Create Leads</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Filters
                    </h3>
                    <p className="font-rubik">
                      Filter leads based on various criteria like lead status, staff assignment, created date,
                      next follow-up date, lead age, leads without activity and many more criteria.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/leadfollowup.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>SELL TICKET</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Lead Follow-ups
                    </h3>
                    <p className="font-rubik">
                      After every activity about the lead, add the follow-up record easily.
                      Choose the mode of communication, notes about the activity, set next follow-up date, change lead status if needed.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/lead_details.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Lead Details
                    </h3>
                    <p className="font-rubik">
                      View lead contact details, lead activity history, lead notes, deals, documents associated with the lead. Communicate with the lead in various channels like email, call, whatsapp or sms.
                      Send a message manually or choose a template message to send personalized message.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="/images/assets/status.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Dashboard</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Lead Status
                    </h3>
                    <p className="font-rubik">
                      Define your own lead statuses as per your sales terminology.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>

              </div>
              <div className="block-style-nine">
                <div className="row align-items-center">
                  <div
                    className="col-lg-7 col-md-9 m-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="illustration-holder">
                      <img src="/images/assets/configurations.png" alt="illustrator" />
                    </div>{" "}
                    {/* /.illustration-holder */}
                  </div>
                  <div
                    className="col-lg-5"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="text-wrapper">
                      {/* <h6>management</h6> */}
                      <h3 className="title font-gilroy-bold">
                        Configurations
                      </h3>
                      <p className="font-rubik">
                        ClientCtrl allows you to configure the “Lead Sources”, “Mode of Communication”, “Lead Disqualify Reasons”,
                        “Welcome emails” etc, so that you can use the software as per your business requirements.
                      </p>
                    </div>{" "}
                    {/* /.text-wrapper */}
                  </div>
                </div>
              </div>{" "}
              {/* /.block-style-nine */}
              <div className="block-style-nine">
                <div className="row align-items-center">
                  <div
                    className="col-lg-7 col-md-9 m-auto order-lg-last"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="illustration-holder">
                      <img src="/images/assets/notification.png" alt="illustrator" />
                    </div>
                    {/* /.illustration-holder */}
                  </div>
                  <div
                    className="col-lg-5 order-lg-first"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="text-wrapper">
                      {/* <h6>Dashboard</h6> */}
                      <h3 className="title font-gilroy-bold">
                        Notifications
                      </h3>
                      <p className="font-rubik">
                        Get notified about every event related to leads like status changes, lead assignments.
                        Staffs are notified by real-time in-app notifications and email notifications.
                      </p>
                    </div>{" "}
                    {/* /.text-wrapper */}
                  </div>

                </div>

              </div>

            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
        <div className="fancy-short-banner-two pt-130 md-pt-70">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
              >
                <h2 className="font-gilroy-bold">
                  Take the first step towards efficiency today!
                </h2>
                <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',border:'1px solid white'}}/>
                {/* <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',background:'white',color:'#7034ff',border:'1px solid black'}}/> */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
      </div>

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default LeadsManagement;
