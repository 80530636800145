import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Storecontext } from "../../../Context/Storecontext";

const MegaMenu = () => {
  const{setshowY}=useContext(Storecontext);
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">
        <a className="nav-link">
        <Link to="/">
          Home
        </Link>
        </a>
        
      </li>
      
      {/* End li */}


      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="/clientctrl-features" data-toggle="dropdown">
          Product
        </a>

        <ul className="dropdown-menu">
          <li>
            <Link to="/clientctrl-features" className="dropdown-item">
              Overview
            </Link>
          </li>
          
          <li>
            <Link to="/features-leads-management" className="dropdown-item">
              Leads & Deals
            </Link>
          </li>
          
          <li>
            <Link to="/features-client-management" className="dropdown-item">
              Client Management
            </Link>
          </li>
          <li>
            <Link to="/features-orders-management" className="dropdown-item">
              Orders & Invoices
            </Link>
          </li>
          <li>
            <Link to="/features-communication" className="dropdown-item">
              Communication
            </Link>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown" onClick={()=> window.localStorage.setItem("showingprice", JSON.stringify(false))}>
        <a className="nav-link" >
        <Link to="/pricing" >
            Pricing
        </Link>
        </a>
        
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li  className="nav-item dropdown">
        <a href="" className="nav-link">
        <Link to="/blog">
          Blog
        </Link>
        </a>
        
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link">
        <Link to="/about">
          About
        </Link>

        </a>
       
        {/* /.dropdown-menu */}
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link">
        <Link to="/contact">
          Contact
        </Link>
        </a>
        
      </li>
      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
