/* eslint-disable react/prop-types */

import { createContext,useState } from "react";
export const Storecontext = createContext(null);

const StoreContextprovider = (props) => {
  const [showY, setshowY] = useState(false);

  const contextvalue = {
    showY,setshowY
  };
  return (
    <Storecontext.Provider value={contextvalue}>
      {props.children}
    </Storecontext.Provider>
  );
};

export default StoreContextprovider;
