import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ClientDemo from "../../../components/demo/ClientDemo";

const Communication = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Communication Marketing Tool | ClientCtrl
        </title>
      </Helmet>
      <meta name="description" content="Simplify your communication with ClientCtrl - conveniently make calls, send emails, WhatsApp messages, and SMS within the portal. Stay on top of your interactions with a comprehensive communication history." />
      {/* End Page SEO Content */}
      <HeaderTwo />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
                <h1 className="heading">Seamlessly Communicate with Clients</h1>
                <p className="sub-heading" style={{paddingBottom:'30px'}}>
                  With ClientCtrl, imagine having all your client conversations neatly organized in one place,
                  easily accessible at your fingertips. With seamless integration and user-friendly interface.
                </p>
                
                <ClientDemo />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven" style={{paddingTop:'0'}}>
        <div className="container">

          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/outbound_calls.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6></h6> */}
                    <h3 className="title font-gilroy-bold">
                      Outbound Calls
                    </h3>
                    <p className="font-rubik">
                      Integrate ClientCtrl with your outbound call provider & make calls from within the CRM.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/mail_lead.png" alt="illustrator" style={{width:'85%',marginLeft:'50px'}}/>
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>SELL TICKET</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Email
                    </h3>
                    <p className="font-rubik">
                      Send email to leads, clients with ease. Use predefined template with 
                      personalization variables to quickly send emails.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/wts_lead.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Whatsapp
                    </h3>
                    <p className="font-rubik">
                      Send whatsapp messages with predefined template messages to leads, clients. Send messages with attachments.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/sms_lead.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Dashboard</h6> */}
                    <h3 className="title font-gilroy-bold">
                      SMS
                    </h3>
                    <p className="font-rubik">
                      Send sms message to leads, clients using template messages. Integrate with your bulk sms provider to send messages.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
        <div className="fancy-short-banner-two pt-130 md-pt-70">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
              >
                <h2 className="font-gilroy-bold">
                  Take the first step towards efficiency today!
                </h2>
                <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',border:'1px solid white'}}/>
                {/* <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',background:'white',color:'#7034ff',border:'1px solid black'}}/> */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
      </div>

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );

      
};

export default Communication;
