import React from "react";
import ClientDemo from "../demo/ClientDemo";
import { Link } from "react-router-dom";




const HeroBannerTwo = () => {
  return (
    <div className="hero-banner-two">
      <div className="container">
        <div className="row align-items-start justify-content-between">
          <div className="col-xl-7 col-lg-8 order-lg-first">
            <div className="hero-text-wrapper md-mt-40">
              <h2 style={{fontWeight: "800", fontFamily: "gilroy-black" }}>
                <span>
                  Stress-free
                </span>
                {" "}Customer Management
                <img
                  src="images/shape/line-shape-1.svg"
                  alt="shape"
                  className="cs-screen"
                />
              </h2>
              <p className="sub-text" style={{ fontSize: "20px", marginTop:'20px'}}>
                The Ultimate All-in-one Customer Management Software for small businesses.
                Maximize efficiency and accelerate Client Relationship Management with ClientCtrl.
              </p>
              <div className="d-flex align-items-start justify-content-start">
                <ClientDemo style={{ height: '55px', width: '200px', fontSize: '20px' }} />
                <Link to="/clientctrl-features" style={{ paddingTop: '0px', paddingLeft: '20px' }}>
                  <u>Learn More</u>
                </Link>
              </div>

            </div>
            {/* /.hero-text-wrapper */}
          </div>
          <div className="col-lg-5 order-lg-last">
            <div className="illustration-holder">
              <img
                // src="images/assets/ils-01.png"
                src="images\assets\homeb1.jpg"
                alt="shape"
                className="illustration_01"
              />
              
            </div>
          </div>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}

      <div className="mt-65 md-mt-50 trusted-companies">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">

            </div>
            <div className="col-12">
              <div className="companies-logo-slider">
                {/* <Brand /> */}
              </div>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
      {/*  /.trusted-companies */}
    </div>
    // End .hero-banner-two
  );
};

export default HeroBannerTwo;
