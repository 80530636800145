import React from "react";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ClientDemo from "../../../components/demo/ClientDemo";


const OrdersManagement = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Track Orders & Payments Seamlessly 
        </title>
        <meta name="description" content="Streamline your workflow and effortlessly manage orders from start to finish with ClientCtrl. Track order statuses, subscriptions, and ensure timely deliveries with ease." />
      </Helmet>
      {/* End Page SEO Content */}
      
      <HeaderTwo />
      {/* End Header */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-10 m-auto">
                <h1 className="heading">Track Orders & Payments Seamlessly </h1>
                <p className="sub-heading" style={{paddingBottom:'30px'}}>
                  Are you tired of spending hours poring over messy order records and struggling to keep your invoices organized?
                  Say goodbye to the chaos with ClientCtrl. Access invoices easily, and track orders & payments seamlessly.
                </p>
                
                <ClientDemo />
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-feature-seven" style={{paddingTop:'0'}}>
        <div className="container">

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/order_browse.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Create Event</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Browse Orders.
                    </h3>
                    <p className="font-rubik">
                      View all orders, filter orders by payment status, delivery status, customer, order value, order date etc.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/order_details.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>View Order Details</h6> */}
                    <h3 className="title font-gilroy-bold">
                      View Order Details
                    </h3>
                    <p className="font-rubik">
                      View products ordered in a order & all associated details. Change the payment status,
                      delivery status of the order. View invoices associated with the order. Track invoice payments & dues.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/new order.png" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Add New Order
                    </h3>
                    <p className="font-rubik">
                      Manually add a order for a client. Select the products, qty & add a new order.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/invoice.png" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    {/* <h6>Dashboard</h6> */}
                    <h3 className="title font-gilroy-bold">
                      Browse Invoices
                    </h3>
                    <p className="font-rubik">
                      View all invoices, filter invoices by invoice number or based on payment status.
                      View invoice details. Send invoice by email, download invoice pdf. Record a payment for the invoice..
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
        <div className="fancy-short-banner-two pt-130 md-pt-70">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                      bg-wrapper
                      d-lg-flex
                      align-items-center
                      justify-content-between
                    "
              >
                <h2 className="font-gilroy-bold">
                  Take the first step towards efficiency today!
                </h2>
                <ClientDemo style={{ height: '70px', width: '200px', fontSize: '20px', border: '1px solid white' }} />
                {/* <ClientDemo style={{ height: '70px', width: '200px',fontSize: '20px',background:'white',color:'#7034ff',border:'1px solid black'}}/> */}
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
      </div>

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default OrdersManagement;
